import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  REGISTER_REQUEST,
  REGISTER_ERROR,
  REGISTER_SUCCESS,
  PASSWORD_RESET,
  PASSWORD_RESET_ERROR,
  PASSWORD_RESET_SUCCESS,
  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_ERROR,
  PROFILE_UPDATE_SUCCESS,
} from "../actions/auth";
import axios from 'axios';
import {Roles} from '@/roles.js';
import router from '@/router';

const defaultErrorMessage = "Something went wrong, please try again or contact us.";

  const state = {
    token: localStorage.getItem("auth-token") || undefined,
    status: "",
    error: undefined,
    timeZone: undefined    
  };
  
  const getters = {
    loading: state => state.status == 'loading',
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.token ? JSON.parse(atob(state.token.split('.')[1])) : undefined,
    userId: (state, getters) => getters.user ? getters.user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] : undefined,
    userRoles: (state, getters) => getters.user 
      ? (Array.isArray(getters.user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']) 
        ? getters.user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
        : [getters.user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']])
      : undefined,
    isAdmin: (state, getters) => (getters.userRoles && getters.userRoles.includes(Roles.Admin)),
    isOrgAdmin: (state, getters) => (getters.userRoles && getters.userRoles.includes(Roles.OrgAdmin)),    
    isStudent: (state, getters) => (getters.userRoles && getters.userRoles.includes(Roles.Student)),    
    isProfessor: (state, getters) => (getters.userRoles && getters.userRoles.includes(Roles.Professor)),    
    userFullName: (state, getters) => getters.user ? 
      getters.user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'] + " " + getters.user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname']
      : undefined,
    userInitials: (state, getters) => getters.user ? 
      getters.user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'].substring(0,1) + getters.user['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'].substring(0,1)
      : undefined,
    organizationId: (state, getters) => getters.user ? getters.user['organizationId'] :  undefined,
    timeZone: state => state.timeZone ?? Intl.DateTimeFormat().resolvedOptions().timeZone    
  };
  
  const actions = {
    [REGISTER_REQUEST]: async ({ commit, /*dispatch*/ }, registerdata) => {
          if(state.status != "loading"){
            commit(REGISTER_REQUEST);
            try{
                let resp = await axios({ url: "auth/register", data: registerdata, method: "POST" })                 
                commit(REGISTER_SUCCESS, {resp: resp, payload: registerdata});                    
            } 
            catch(err){
              console.error(err);
              commit(REGISTER_ERROR, err);                
            }
          }
    },
    [AUTH_REQUEST]: async ({ commit, getters, dispatch }, logindata) => {
        if(state.status != "loading"){
            commit(AUTH_REQUEST);
            try{
                if(router.currentRoute.name == 'LoginSSO')
                  logindata.sso = true
                let resp = await axios({ url: "auth/login", data: logindata, method: "POST" });                             
                localStorage.setItem("auth-token", resp.data.token);                           
                axios.defaults.headers.common['Authorization'] = "Bearer " + resp.data.token;
                commit(AUTH_SUCCESS, resp);  
                //console.log(router)
                if(router.currentRoute.name == 'LoginSSO'){
                  console.log("redirecting to drupal")                  
                  window.location = (router.currentRoute.query.botiLocal ? 'https://avris-portal.ddev.site:4443/' : process.env.VUE_APP_PORTAL_URL) + 'api/user/jwt' + `?code=${resp.data.code}&redirectPath=${encodeURIComponent(router.currentRoute.query.redirectPath)}`
                }
                else{
                  dispatch('getExperiences');
                  // route based on user role, prof to dashboard, user to pin generation screen, etc..                   
                  (getters.isProfessor || getters.isAdmin || getters.isOrgAdmin)
                    ? router.push('/upcoming').catch(() => {}) // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
                    : router.push('/').catch(() => {})
                }
            }
            catch(err){
                //console.log(err)
                commit(AUTH_ERROR, err);
                localStorage.removeItem("auth-token");                               
            }
        }
    },
    [AUTH_LOGOUT]: async ({ commit }) => {
        commit(AUTH_LOGOUT);
        localStorage.removeItem("auth-token");
        axios.defaults.headers.common['Authorization'] = null;
        if(router.currentRoute.name != 'Login')
          router.push('/login')  
    },
    [PASSWORD_RESET]: async ({ commit }, resetdata) => {
      if(state.status != "loading"){
          commit(PASSWORD_RESET);
          try{
              let resp = await axios({ url: "auth/passwordreset", data: resetdata, method: "POST" }); 
              commit(PASSWORD_RESET_SUCCESS, resp);        
              router.push('/login').catch(() => {});
          }
          catch(err){
              commit(PASSWORD_RESET_ERROR, err);                              
          }
      }
    },
    [PROFILE_UPDATE_REQUEST]: async ({ commit }, profile) => {
      if(state.status != "loading"){
          commit(PROFILE_UPDATE_REQUEST);
          try{
              let resp = await axios({ url: "auth/profile", data: profile, method: "POST" }); 
              localStorage.setItem("auth-token", resp.data.token);                           
              axios.defaults.headers.common['Authorization'] = "Bearer " + resp.data.token;
              commit(PROFILE_UPDATE_SUCCESS, resp);  
          }
          catch(err){
              commit(PROFILE_UPDATE_ERROR, err);                              
          }
      }
    }  
  };
  
  const mutations = {
    [PASSWORD_RESET]: state => {
      state.status = "loading";
      state.error = undefined;
    }, 
    [PASSWORD_RESET_SUCCESS]: state => {
      state.status = "pwd_reset_success";
      state.error = undefined;
    }, 
    [PASSWORD_RESET_ERROR]: (state, err) => {
      state.status = "pwd_reset_error";
      state.error = err.response 
        ? (err.response.data.title ? err.response.data.title : err.response.data) 
        : defaultErrorMessage;
    }, 
    [AUTH_REQUEST]: state => {
      state.status = "loading";
      state.error = undefined;
    },    
    [AUTH_SUCCESS]: (state, resp) => {
      state.status = "auth_success";
      state.token = resp.data.token; 
      state.timeZone = resp.data.timeZone
    },
    [AUTH_ERROR]: (state, err) => {
      state.status = "auth_error";
      state.error = err.response ? err.response.data : defaultErrorMessage;
    },
    [AUTH_LOGOUT]: state => {
      state.token = "";
    },    
    [REGISTER_REQUEST]: state => {
      state.status = "loading";
      state.error = undefined;
    },
    [REGISTER_SUCCESS]: (state, {resp, payload}) => {
        if(resp.status == 200){
          state.status = "register_success_approved";
          router.push('/login?email=' + encodeURIComponent(payload.email)).catch(() => {});
        }
        else if(resp.status == 202){
          state.status = "register_success";
          router.push('/confirmemail?email=' + encodeURIComponent(payload.email)).catch(() => {});
        }
        else{
          state.status = "register_error";
          state.error = "Unexpected API status code: " + resp.status;
        }
    },
    [REGISTER_ERROR]: (state, err) => {
        //debugger
        state.status = "register_error";
        state.error = err.response ? err.response.data : defaultErrorMessage;
    },
    [PROFILE_UPDATE_REQUEST]: state => {
      state.status = "loading";
      state.error = undefined;
    },
    [PROFILE_UPDATE_SUCCESS]: (state, resp) => {
        state.status = "profile_update_success";
        state.token = resp.data.token
        state.timeZone = resp.data.timeZone
    },
    [PROFILE_UPDATE_ERROR]: (state) => {
        state.status = "profile_update_error";
        state.error = defaultErrorMessage;
    }
  };
  
  export default {
    state,
    getters,
    actions,
    mutations
  };