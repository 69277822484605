import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VCalendar from 'v-calendar'
Vue.use(VCalendar)
// Vue.use(VCalendar, {
//   // componentPrefix: 'vc',  // Use <vc-calendar /> instead of <v-calendar />
//   // ...,                // ...other defaults
// });

import { ToggleButton } from 'vue-js-toggle-button'
Vue.component('ToggleButton', ToggleButton)

Vue.config.productionTip = false

import 'nprogress/nprogress.css';
import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false });
Vue.prototype.$nprogress = NProgress;

import Constants from '@/constants.js'
Vue.prototype.$constants = Constants

Array.prototype.move = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
