import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./modules/auth";
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sidebarCollapsed: false,
    editorWebContentRootPath: '',
    showGettingStarted: undefined,
    showLoader: true,
    experiences: []
  },
  getters: {    
    showGettingStarted(state){ 
      return (state.showGettingStarted || state.showGettingStarted == undefined) && !localStorage.getItem('skipintro') && !sessionStorage.getItem('skipintro')
    },
    orderedExperiences(state){
      return state.experiences.sort((a, b) => a.name?.localeCompare(b.name, undefined, {sensitivity: 'base'})) 
    }
  },
  mutations: {  
    toggleSidebar(state) {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    showGettingStarted(state) {
      localStorage.removeItem('skipintro')
      sessionStorage.removeItem('skipintro')
      state.showGettingStarted = true
    },
    hideGettingStarted(state, dontShowAgain) {
      // if dontshow -> store in local storage
      if(dontShowAgain)
        localStorage.setItem('skipintro', true)      
      else
        sessionStorage.setItem('skipintro', true)
      state.showGettingStarted = false
    },
    setExperiences(state, exp){
      state.experiences = exp
    }
  },
  actions: {
    async getExperiences({commit}){ 
      let resp = await axios({ url: "experiences" }) 
      commit('setExperiences', resp.data)
    }
  },
  modules: {
    auth
  }
})
