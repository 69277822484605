<template>
  <div class="intro themev2" v-if="$store.getters.showGettingStarted">
    <button class="close-button" @click="getStarted" title="Close getting started page" />
    <div style="font-size:16px; margin-bottom: 7px;">Getting started</div>
    <h1>Immersive Session Dashboard</h1>
    <div  class="grid">      
      <div style="display:flex; gap: 8px;">
        <div><h2>1.</h2></div>
        <div>
          <h2>Choose<br><span>your experience</span></h2>
          <p>
            Browse the library to choose the experience you would like to teach.
            <br>
            <br>
            Already know what you want to teach? Skip to the next steps.
          </p>
          <a href="/xrlibrary" @click="getStarted">Browse the library</a>
        </div>
      </div>      
      <div style="display:flex; gap: 8px;">
        <div><h2>2.</h2></div>
        <div>
          <h2>Get to know<br><span>the experience</span></h2>
          <p>
            Dive into the details - in the Digital Playbook, you'll find everything you need to know about the experience. 
            <br>
            <br>
            You will find teaching assets such as slide decks and media assets to help you prepare and your class to engage.
          </p>
          <a href="/xrlibrary">Find the playbook for each experience</a>
        </div>      
      </div>      
      <div style="display:flex; gap: 8px;">
        <div><h2>3.</h2></div>
        <div>
          <h2>Prepare<br><span>your session</span></h2>
          <p>
            It's time to schedule a session for teaching!
            <br>
            <br>
            Head to your dashboard to schedule it. Your organizational admin will receive the information and let you know when the session is ready.
          </p>
          <a href="/upcoming">Go to session dashboard</a>
        </div>      
      </div>      
      <div style="display:flex; gap: 8px;">
        <div><h2>4.</h2></div>
        <div>
          <h2>Teach<br><span>a live class</span></h2>
          <p>
            Your session is ready for teaching!
            <br>
            <br>
            In the classroom, you can play segments, present results through the dashboard and debrief between segments just like you planned.
          </p>
          <a href="/upcoming">Go to session dashboard</a>
        </div>
      </div>
      <img src="../assets/gs1.svg">
      <img src="../assets/gs2.svg">
      <img src="../assets/gs3.svg">
      <img src="../assets/gs4.svg">

      <div class="cta">
        <button style="border-radius: 20px; border: none; padding-left: 40px; padding-right:40px; margin-bottom: 15px;" @click="getStarted">Get started</button>
        <br>
        <input type="checkbox" style="width:20px; height=20px; vertical-align:middle;" v-model="dontShowAgain" /> <span style="font-size: 14px;color:#777;">Don't show again</span>
      </div>
    </div>    
  </div>
</template>

<script>


export default {
  name: 'GettingStarted', 
  data: function(){
    return{    
      dontShowAgain: false
    }
  },
  computed: {    
  },
  methods: {
    getStarted(){      
      this.$store.commit('hideGettingStarted', this.dontShowAgain)
    }
  }
}
</script>

<style lang="scss">

.intro{
  position: fixed;
  right: 0;
  bottom: 0;
  left: var(--size-sidebar); 
  top: var(--size-header); 
  background: $bg-grey; 
  padding: 65px 88px;
  z-index: 11;
  
  overflow-y: scroll;

  .close-button{
    position: absolute;
    right: 30px;
    top: 30px;
    width: 50px;
    min-width: 50px;
    height: 50px;
    cursor: pointer;
    border: none;
    background: url(../assets/close.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    padding: 0;
    margin: 0;
    opacity: 0.6;

    &:hover{
      opacity: 1;
      background: url(../assets/close.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-color: transparent;
    }
  }

  h1{
    font-size: 40px;
  }
  h2{
    font-size: 30px;
    margin-bottom:0;
    span{
      font-size:24px; 
      line-height:34px;      
    }
  }

  .grid{
    display: grid;
    column-gap: 25px;
    row-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;

    p{
      font-size: 16px;
      color: #777;
      line-height: unset;
    }

    img {
      justify-self: center; 
      max-width: 140px;
    }

  }

  .cta{    
    grid-column-start: 1;
    grid-column-end: span 4;
    grid-row-start: 3;
    text-align: center; 
  }
}

</style>
